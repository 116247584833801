/**
 * Custom Range datepicker wrapper.
 */
;(function ($) {
    'use strict';

    $.CustomRangeDatepicker = {
        /**
         *
         *
         * @var Object _baseConfig
         */
        _baseConfig: {},

        /**
         *
         *
         * @var jQuery pageCollection
         */
        pageCollection: $(),

        /**
         * Initialization of Range datepicker wrapper.
         *
         * @param String selector (optional)
         * @param Object config (optional)
         *
         * @return jQuery pageCollection - collection of initialized items.
         */

        init: function (selector, config) {

            this.collection = selector && $(selector).length ? $(selector) : $();
            if (!$(selector).length) return;

            this.config = config && $.isPlainObject(config) ?
                $.extend({}, this._baseConfig, config) : this._baseConfig;

            this.config.itemSelector = selector;

            this.initRangeDatepicker();

            return this.pageCollection;

        },

        initRangeDatepicker: function () {
            //Variables
            var $self = this,
                collection = $self.pageCollection;

            //Actions
            this.collection.each(function (i, el) {
                //Variables
                var $this = $(el),
                    optWrapper = $this.data('rp-wrapper'),
                    optIsInline = Boolean($this.data('rp-is-inline')),
                    optType = $this.data('rp-type') || 'single',
                    optDateFormat = $this.data('rp-date-format'),
                    optIsDisableFutureDates = $this.data('rp-is-disable-future-dates'),
                    optDefaultDate = optType === 'single' ? $this.data('rp-default-date') : JSON.parse(el.getAttribute('data-rp-default-date')),
                    optMinDate = $this.data("rp-min-date"),
                    optMaxDate = $this.data("rp-max-date") || (!!optIsDisableFutureDates && 'today'),
                    optAllowInput = $this.data("rp-allow-input");
                if ($this.data("rp-locale") === 'fr') {
                  flatpickr.localize(flatpickr.l10ns.fr);
                  var shorthand = ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"]
                } else {
                  var shorthand = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]
                }
                $this.flatpickr({
                    inline: optIsInline, // boolean
                    mode: optType, // 'single', 'multiple', 'range'
                    dateFormat: optDateFormat ? optDateFormat : 'd M Y',
                    defaultDate: optDefaultDate,
                    appendTo: $(optWrapper)[0],
                    maxDate: optMaxDate,
                    minDate: optMinDate,
                    allowInput: optAllowInput,
                    locale: {
                        firstDayOfWeek: 1,
                        weekdays: {
                            shorthand: shorthand
                        },
                        rangeSeparator: ' - '
                    }
                });

                $this.css({
                    width: $this.val().length * 7.5
                });

                //Actions
                collection = collection.add($this);
            });
        }
    };
})(jQuery);
