(function($) {
  'use strict';
  $.MoneyMask = {
    init: function(selector) {
      $(selector).each(function(index, element) {
        var $element = $(element);
        $element.data('cleave', new Cleave($element, {
          numeral: true,
          numeralPositiveOnly: true,
          stripLeadingZeroes: true,
          noImmediatePrefix: true,
          prefix: $element.data('unit'),
          delimiter: $element.data('delimiter'),
          numeralDecimalMark: $element.data('separator'),
          numeralDecimalScale: $element.data('precision')
        }));
      });
    }
  };
})(jQuery);
