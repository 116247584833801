(function($) {
  'use strict';
  $.TextAreaCounter = {
    init: function(selector) {
      $(selector).each(function(index, element) {
        var $element = $(element);
        $element.bind('input', function() {
          var maxlength = $element.attr("maxlength");
          var currentLength = this.value.length;
          var $counter = $('#' + $element.attr("id") + '_counter');

          $counter.text(currentLength >= maxlength ? 0 : maxlength - currentLength);
        })
      });
    }

  };
})(jQuery);
