$(document).ready(function () {
  var inputs = document.querySelectorAll("input[required]");
  var selects = document.querySelectorAll("select[required]");
  applyCustomValidationMessage(inputs)
  applyCustomValidationMessage(selects)
});


// Override browser's required error message with locale language
function applyCustomValidationMessage(elements) {
  elements.forEach(function (ele) {
    ele.addEventListener("input", function (e) {
      this.setCustomValidity("");
    });

    ele.addEventListener("invalid", function (e) {
      if (this.validity.valueMissing) {
        var isFrench = gon.locale === "fr";
        var isSelect = this.nodeName === "SELECT";
        var message;

        if (isSelect && !isFrench) message = "Please select an item in the list.";
        if (isSelect && isFrench) message = "Veuillez sélectionner un élément dans la liste.";
        if (!isSelect && !isFrench) message = "Please fill out this field.";
        if (!isSelect && isFrench) message = "Veuillez remplir cette section";
        
        if(message) this.setCustomValidity(message);
      }
    });
  });

}
