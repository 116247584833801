import 'autonumeric/dist/autoNumeric'
import 'bootstrap-select/dist/js/bootstrap-select'
import 'jquery-validation/dist/jquery.validate'
import 'jquery.maskedinput/src/jquery.maskedinput'
import 'cleave.js/dist/cleave'
import 'cleave.js/dist/addons/cleave-phone.ca'
import "@lendingloop/front-template/vendor/flatpickr/dist/flatpickr.js";
import "@lendingloop/front-template/vendor/flatpickr/dist/l10n/fr.js";
import './front_custom_components/custom-range-datepicker'
import './masking'
import './form-elements'
import './form-js-validations'
import './form-upload'

$(document).ready(function () {
  $.CustomRangeDatepicker.init('.js-range-datepicker');
});
