(function($) {
  'use strict';

  $.PhoneMask = {
    init: function(selector, regionCode) {
      $(selector).each(function(index, element) {
        var $element = $(element);
        $element.data('cleave', new Cleave($element, {
          phone: true,
          phoneRegionCode: regionCode
        }));
      });
    }
  };
})(jQuery);
