import './form_fields/text-area-counter';
import './form_fields/phone-mask';
import './form_fields/money-mask';
import './form_fields/date-mask';

(function($) {
    'use strict';
    window.FormElements = {
        init: function(options) {
            if (!options) {
                options = {};
            }
            // Initialize text area counter
            var textAreaClass = options.enable_counter_class || 'enable-counter';
            $.TextAreaCounter.init('.' + textAreaClass);

            // Initialize date picker
            var datePickerClass = options.date_picker_field_class || 'js-form-datepicker';
            $.CustomRangeDatepicker.init('.' + datePickerClass);

            // Initialize phone mask
            var phoneMaskClass = options.phone_mask_class || 'enable-phone-mask';
            var regionCode = options.region_code || 'CA';
            $.PhoneMask.init('.' + phoneMaskClass, regionCode);

            // Initialize phone mask
            var moneyMaskClass = options.money_mask_class || 'enable-money-mask';
            $.MoneyMask.init('.' + moneyMaskClass);

            if (!options.mobile_device) {
                var dateMaskClass = options.date_field_class || 'js-form-datepicker';
                $.DateMask.init('.' + dateMaskClass);
            }
        }
    };
})(jQuery);
