(function($) {
  'use strict';
  $.DateMask = {
    init: function(selector) {
      $(selector).each(function(index, element) {
        var $element = $(element);
        $element.data('cleave', new Cleave($element, {
          date: true,
          datePattern: ['d', 'm', 'Y']
        }));
      });
    }
  };
})(jQuery);
